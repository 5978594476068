var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"4.11.1"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'
import { getSentryErrorData } from './shared-lib/utils'
import Analytics from './src/stores/analytics'

const SENTRY_DSN: string =
  process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN || ''

const isDevelopment = process.env.SS_ENV === 'development'
const isSentryEnabled = process.env.SENTRY_DEV_ENABLED === 'true'

Sentry.init({
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  dsn: SENTRY_DSN,
  environment: process.env.SS_ENV,
  enabled: !isDevelopment || isSentryEnabled,
  attachStacktrace: true,
  ignoreErrors: [
    // Email link Microsoft Outlook crawler compatibility error
    // cf. https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
    'Non-Error promise rejection captured',
    // Ignore resize observer loop https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402/5
    'ResizeObserver loop limit exceeded',
  ],
  normalizeDepth: 5,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  beforeSend: event => {
    Analytics.track('Sentry Error', getSentryErrorData(event))
    return event
  },
  integrations: [
    Sentry.replayIntegration({
      // https://docs.sentry.io/platforms/javascript/session-replay/configuration/
      maskAllText: false,
      blockAllMedia: true,
    }),
  ],
})