interface LatLngObject {
  lat: any
  lng: any
}

export const hasLatLng = (address: unknown): address is LatLngObject => {
  if (address && typeof address === 'object') {
    return 'lat' in address && 'lng' in address
  }
  return false
}

interface LocationObject {
  location: GeoJsonPoint | undefined
}

export const hasLocation = (address: unknown): address is LocationObject => {
  if (address && typeof address === 'object') {
    return 'location' in address
  }
  return false
}