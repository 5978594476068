export enum AccountingType {
  Bank = 'bank',
  Card = 'card',
  Cash = 'cash',
  CrmInvoices = 'crmInvoices',
  Other = 'other',
  PaymentFees = 'paymentFees',
  SecretSpaFees = 'secretSpaFees',
  SecretSpaPayments = 'secretSpaPayments',
  SoloPayments = 'soloPayments',
  SoloReferrals = 'soloReferrals',
  SoloRefunds = 'soloRefunds',
  SubscriptionsPaid = 'subscriptionsPaid'
}

export enum ActivityStatus {
  Hidden = 'hidden',
  Interacted = 'interacted',
  Read = 'read',
  Unread = 'unread'
}

export enum AdvancePaymentOptionId {
  FullDeposit = 'FULL_DEPOSIT',
  No = 'NO',
  PartialDeposit = 'PARTIAL_DEPOSIT'
}

export enum AutomatedTriggerType {
  ClientReferralAlmost = 'clientReferralAlmost',
  ClientReferralCompleted = 'clientReferralCompleted',
  ClientReferralHalf = 'clientReferralHalf',
  FirstBooking = 'firstBooking',
  Lapsed = 'lapsed',
  LoyaltyAlmost = 'loyaltyAlmost',
  LoyaltyCompleted = 'loyaltyCompleted',
  LoyaltyHalf = 'loyaltyHalf',
  OfflineBooking = 'offlineBooking',
  TrBookingAccepted = 'tr_bookingAccepted',
  TrBookingCancellationRequested = 'tr_bookingCancellationRequested',
  TrBookingCancelled = 'tr_bookingCancelled',
  TrBookingCompleted = 'tr_bookingCompleted',
  TrBookingConsultationFormReminder = 'tr_bookingConsultationFormReminder',
  TrBookingDeclined = 'tr_bookingDeclined',
  TrBookingReminder = 'tr_bookingReminder',
  TrBookingRequested = 'tr_bookingRequested',
  TrBookingUpdated = 'tr_bookingUpdated',
  TrPaymentReceipt = 'tr_paymentReceipt',
  UpcomingBirthday = 'upcomingBirthday'
}

export enum CrmMessageType {
  Accepted = 'accepted',
  Automated = 'automated',
  Broadcast = 'broadcast',
  Cancelled = 'cancelled',
  Completed = 'completed',
  Consultation = 'consultation',
  Reminder = 'reminder',
  Updated = 'updated'
}

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export enum CampaignMessageType {
  Email = 'email',
  Sms = 'sms'
}

export enum CampaignStatus {
  Active = 'active',
  Archived = 'archived',
  Draft = 'draft',
  Paused = 'paused',
  Scheduled = 'scheduled',
  Sending = 'sending',
  Sent = 'sent'
}

export enum CampaignType {
  Automated = 'automated',
  Broadcast = 'broadcast'
}

export enum CancellationReason {
  Cancelled = 'cancelled',
  NoShow = 'noShow'
}

export enum CheckoutMode {
  Payment = 'payment',
  Subscription = 'subscription'
}

export enum CompletePaymentMethodOptionId {
  Bank = 'bank',
  Cash = 'cash',
  Machine = 'machine',
  Other = 'other',
  QrCode = 'qrCode',
  SavedCard = 'savedCard',
  Stripe = 'stripe',
  TapToPay = 'tapToPay'
}

export enum ConsultationFormStatus {
  Completed = 'COMPLETED',
  NotRequired = 'NOT_REQUIRED',
  ReminderSent = 'REMINDER_SENT',
  Requested = 'REQUESTED'
}

export enum ContactType {
  Email = 'EMAIL',
  Facebook = 'FACEBOOK',
  Instagram = 'INSTAGRAM',
  Phone = 'PHONE',
  Tiktok = 'TIKTOK'
}

export enum CrmMessageStatus {
  Clicked = 'clicked',
  Delivered = 'delivered',
  Opened = 'opened',
  Sent = 'sent'
}

export enum CrmUsageType {
  Email = 'email',
  Sms = 'sms'
}

export enum DeletableModelNames {
  ConsultationForm = 'ConsultationForm',
  ExternalPromoCode = 'ExternalPromoCode',
  ExternalReview = 'ExternalReview',
  ExternalTreatment = 'ExternalTreatment',
  ProfessionalAvailability = 'ProfessionalAvailability',
  Review = 'Review'
}

export enum DiaryEventWarningStatus {
  Bad = 'bad',
  None = 'none',
  Warn = 'warn'
}

export enum DiscountType {
  Monies = 'monies',
  Percent = 'percent'
}

export enum EmailContentBlockType {
  BoldText = 'boldText',
  BookingDateAndLocation = 'bookingDateAndLocation',
  BookingItems = 'bookingItems',
  Button = 'button',
  CancellationPolicy = 'cancellationPolicy',
  ClientReferralScheme = 'clientReferralScheme',
  Ctas = 'ctas',
  DeclineReason = 'declineReason',
  HeaderImage = 'headerImage',
  HorizontalLine = 'horizontalLine',
  Image = 'image',
  LoyaltyScheme = 'loyaltyScheme',
  Promocode = 'promocode',
  SocialLinks = 'socialLinks',
  Text = 'text',
  Title = 'title'
}

export enum ExternalBookingPaymentStatus {
  DepositNotPaid = 'DEPOSIT_NOT_PAID',
  DepositPaid = 'DEPOSIT_PAID',
  FullyPaid = 'FULLY_PAID',
  NoDepositNotPaid = 'NO_DEPOSIT_NOT_PAID',
  NoDepositPartiallyPaid = 'NO_DEPOSIT_PARTIALLY_PAID'
}

export enum ExternalBookingSource {
  BookingPortal = 'BOOKING_PORTAL',
  Marketplace = 'MARKETPLACE',
  ProApp = 'PRO_APP'
}

export enum ExternalBookingStatus {
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  CancelRequested = 'CANCEL_REQUESTED',
  Completed = 'COMPLETED',
  Declined = 'DECLINED',
  Draft = 'DRAFT',
  Requested = 'REQUESTED',
  ToComplete = 'TO_COMPLETE'
}

export enum ExternalClientGroups {
  FrequentClients = 'frequentClients',
  NewClients = 'newClients',
  NoBookingLast30 = 'noBookingLast30',
  TopSpenders = 'topSpenders',
  UpcomingBirthday = 'upcomingBirthday'
}

export enum ExternalPaymentStatus {
  Cleared = 'CLEARED',
  Processing = 'PROCESSING'
}

export enum GeoJsonPointType {
  Point = 'Point'
}

export enum LoyaltySchemeGoalType {
  Booking = 'booking',
  Spend = 'spend'
}

export enum LoyaltySchemeLogType {
  BookingCompleted = 'bookingCompleted',
  CurrentManualChange = 'currentManualChange',
  GoalChange = 'goalChange',
  GoalNumberChange = 'goalNumberChange',
  SchemeCompleted = 'schemeCompleted'
}

export enum LoyaltySchemeStatus {
  Active = 'active',
  Draft = 'draft'
}

export enum LoyaltySchemeUsageType {
  ClientReferral = 'clientReferral',
  Default = 'default'
}

export enum MarketplaceContactDetailType {
  Email = 'EMAIL',
  Phone = 'PHONE',
  Website = 'WEBSITE'
}

export enum MarketplaceTagCategory {
  Brand = 'Brand',
  Certification = 'Certification',
  Price = 'Price',
  Service = 'Service',
  Treatment = 'Treatment',
  Verification = 'Verification'
}

export enum MessageType {
  Modal = 'MODAL'
}

export enum PaymentMethodOptionId {
  ManualCollection = 'MANUAL_COLLECTION',
  PaymentLink = 'PAYMENT_LINK'
}

export enum PaymentTypes {
  Bank = 'bank',
  Cash = 'cash',
  Machine = 'machine',
  Other = 'other',
  Stripe = 'stripe',
  StripeRefund = 'stripe_refund'
}

export enum PlanType {
  Grow = 'grow',
  Pro = 'pro',
  Starter = 'starter'
}

export enum Platform {
  Ca = 'CA',
  Pa = 'PA',
  Wb = 'WB'
}

export enum QuestionType {
  Image = 'IMAGE',
  LongText = 'LONG_TEXT',
  MultipleChoice = 'MULTIPLE_CHOICE',
  SingleChoice = 'SINGLE_CHOICE',
  Text = 'TEXT',
  YesOrNo = 'YES_OR_NO'
}

export enum RedeemableBy {
  Anyone = 'anyone',
  Custom = 'custom',
  ExistingClients = 'existingClients',
  NewClients = 'newClients'
}

export enum ScheduledCampaignStatus {
  Cancelled = 'cancelled',
  Draft = 'draft',
  Scheduled = 'scheduled',
  Sent = 'sent'
}

export enum SoloReferralAction {
  NewPortalRequest = 'NEW_PORTAL_REQUEST',
  SignUp = 'SIGN_UP',
  Subscribe = 'SUBSCRIBE',
  Trial = 'TRIAL'
}

export enum SoloReferralRewardStatus {
  Paid = 'PAID',
  Unpaid = 'UNPAID'
}

export enum SoloReferralRewardType {
  Subscription = 'SUBSCRIPTION'
}

export enum SoloReferralStatus {
  ReceivedBooking = 'RECEIVED_BOOKING',
  SignedUp = 'SIGNED_UP',
  Subscribed = 'SUBSCRIBED',
  Trial = 'TRIAL'
}

export enum SoloSubscriptionPlatform {
  Android = 'android',
  Ios = 'ios',
  Web = 'web'
}

export enum SpecialQuestions {
  Comments = 'comments'
}

export enum StripeAccountStatus {
  Completed = 'COMPLETED',
  Errors = 'ERRORS',
  NotSubmitted = 'NOT_SUBMITTED',
  Pending = 'PENDING',
  Restricted = 'RESTRICTED'
}

export enum StripePaymentTypes {
  Link = 'link',
  SavedCard = 'saved_card',
  TapToPay = 'tap_to_pay'
}

export enum StripeTransactionType {
  Fee = 'fee',
  Payment = 'payment',
  Payout = 'payout',
  Referral = 'referral',
  Refund = 'refund',
  SsPayout = 'ss_payout'
}

export enum UpfrontPaymentClientType {
  Existing = 'existing',
  New = 'new'
}

export enum UpfrontPaymentOption {
  Approval = 'approval',
  Checkout = 'checkout',
  None = 'none',
  SaveCard = 'save_card'
}

export enum WebPageBlockType {
  Text = 'TEXT'
}

export enum WebPageVisibility {
  Hidden = 'HIDDEN',
  Visible = 'VISIBLE'
}
