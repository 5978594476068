import packageJSON from '../../package.json'
import { toJS } from 'mobx'
import { AnalyticsBrowser } from '@segment/analytics-next'
import { getBrowserTimezone } from '../../shared-lib/utils'
import addKeyPrefix from '../../shared-lib/lib/addKeyPrefix'
import { $ExternalBooking } from '../models/booking'
import { bookingTotal } from '../helpers/bookingHelpers'
import moment from 'moment'

const analytics = AnalyticsBrowser.load({ writeKey: process.env.SEGMENT_WRITE_KEY! })

class Analytics {
  config: any = {}
  campaignData: Record<string, any> = {}
  sessionContext: Record<string, any> = {}
  userTraits: Record<string, any> = {}
  testGroups: Record<string, boolean | number | string> = {}

  get analytics() {
    return analytics
  }

  init() {
    this.config.platform = process.env.PLATFORM
    this.config.name = process.env.SS_ENV
    this.config.timezone = getBrowserTimezone()
    this.config.version = packageJSON.version
  }

  loadCampaignData = () => {
    const paramsKey = 'attributionParams'
    const paramsAtKey = 'attributedAt'

    const lastAttributedAt = parseInt(window.localStorage.getItem(paramsAtKey) || '0')
    const msSinceAttributed = Date.now() - lastAttributedAt
    const aDayInMs = 8.64e+7
    const paramsExpired = msSinceAttributed > aDayInMs

    // On the webflow any params on the initial link a customer clicks are saved to local storage as an object.
    // We can pull them out and send them with analytic events
    const storedParams = window.localStorage.getItem(paramsKey)

    if (storedParams && !paramsExpired) {
      this.campaignData = JSON.parse(storedParams)
      return
    }

    // Otherwise use the search params if they exist
    const params = Object.fromEntries(new URLSearchParams(location.search))
    const allowedKeys = ['ref', 'campaign', 'clid', 'utm', 'session', 'track']
    const filteredParams: Record<string, string> = {}
    Object.keys(params).forEach(key => {
      const value = params[key]
      if (value && allowedKeys.some(allowedKey => key.includes(allowedKey))) {
        filteredParams[key] = value
      }
    })

    if (Object.keys(filteredParams).length > 0) {
      this.campaignData = filteredParams
      window.localStorage.setItem(paramsKey, JSON.stringify(filteredParams))
      window.localStorage.setItem(paramsAtKey, `${Date.now()}`)
    }
  }

  track = (eventName: string, data?: any) => {
    const parsedData = this.parseProperties({
      ...toJS(data),
      ...this.getContext(),
    })

    if (this.config.name === 'development') console.info('Analytics Track:', eventName, parsedData)
    this.analytics?.track(eventName, parsedData)
  }

  page = (pageName: string, data?: any) => {
    const parsedData = this.parseProperties({
      ...toJS(data),
      ...this.getContext(),
    })

    if (this.config.name === 'development') console.info('Analytics Page:', pageName, parsedData)
    this.analytics?.page(pageName, parsedData)
  }

  identify = (id: string, data: Record<string, string>) => {
    this.analytics?.identify(id, { ...data })
  }

  parseProperties = (properties: Record<string, any>) => {
    const parsedProperties = { ...properties }

    // Ensure dates are parsed as strings
    for (const key in parsedProperties) {
      if (parsedProperties[key] instanceof Date) parsedProperties[key] = parsedProperties[key].toISOString()

      if (parsedProperties[key] != null) {
        const omitTypename = (key: string | undefined, value: any) => (key === '__typename' ? undefined : value)
        parsedProperties[key] = JSON.parse(JSON.stringify(parsedProperties[key]), omitTypename)
      }
    }

    return parsedProperties
  }

  resetSessionContext = () => {
    this.sessionContext = {}
  }

  setSessionContext = (newContext: Record<string, any>) => {
    for (const i in newContext) {
      this.sessionContext[`${i}`] = newContext[i]
    }
  }

  getContext() {
    const { clientHeight, clientWidth } = document.documentElement

    return {
      ...addKeyPrefix(this.campaignData, 'A'),
      ...addKeyPrefix(this.sessionContext, 'S'),
      ...addKeyPrefix(this.userTraits, 'U'),
      ...addKeyPrefix(this.testGroups, 'AB'),
      platform: this.config.platform,
      timezone: this.config.timezone,
      SS_ENV: this.config.name,
      version: this.config.version,
      screenWidth: clientWidth,
      screenHeight: clientHeight,
      orientation: clientHeight > clientWidth ? 'vertical' : 'horizontal',
    }
  }

  // For InAppMessageHandler compatibility
  onTrack(_event: ((eventName: string, properties: Record<string, any>, userTraits: Record<string, any>) => void)) {
    return
  }

  bookingAnalytics = (booking: $ExternalBooking) => {
    return {
      _id: booking._id,
      currency: 'GBP',
      status: booking.status,
      date: booking.date ? moment(booking.date).toString() : '',
      totalFee: bookingTotal(booking.items) / 100,
      items: booking.items.map(i => ({
        currency: 'GBP',
        _id: i.treatmentId,
        name: i.name,
        fee: i.fee / 100,
        quantity: 1,
      })),
    }
  }
}

export default new Analytics()
