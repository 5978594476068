import 'intl'
import 'intl/locale-data/jsonp/en'
import { defaultCurrency } from './i18n/i18n'

export const penceToPounds = (pence: number): number => pence / 100
export const poundsToPence = (pounds: number): number => pounds * 100
export const currencyFormat = (
  balance: number,
  fractionDigits = 2,
  pennies = true,
  locale = 'en-GB',
  currency = defaultCurrency,
) => new Intl.NumberFormat(locale, {
  style: 'currency',
  currency,
  maximumFractionDigits: fractionDigits,
  minimumFractionDigits: fractionDigits,
})
  .format(pennies ? penceToPounds(balance) : balance)

export const formatCurrencyForStats = (amount: number, locale = 'en-GB', currency = defaultCurrency): string => {
  const pounds = penceToPounds(amount)
  const symbol = currencySymbol(locale, currency)

  if (pounds >= 1000) {
    if ((pounds / 1000) % 1 === 0) return symbol + `${pounds / 1000}k`

    return symbol + `${(pounds / 1000).toFixed(1)}k`
  }

  return symbol + `${pounds.toFixed(0)}`
}

export const currencySymbol = (locale = 'en-GB', currency = defaultCurrency) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  })

  if (!formatter.formatToParts) {
    // https://github.com/facebook/hermes/issues/1188
    // Not currently supported in Hermes on iOS
    const managedCurrencies: Record<string, string> = {
      'gbp': '£',
      'usd': '$',
      'eur': '€',
    }
    return managedCurrencies[currency] || ''
  }

  let symbol
  formatter.formatToParts(0).forEach(({ type, value }) => {
    if (type === 'currency') {
      symbol = value
    }
  })

  return symbol || ''
}