import { LocationDetails } from '../../components/LocationPage/LocationWithMapCard'
import { ExternalTreatmentMenuQuery } from '../../graphQL/generated/client'
import { ExternalBookingStatus } from '../../graphQL/generated/enums'
import { MOBILE_AVAILABILITY_ID } from '../../shared-lib/commonStrings'
import { generateObjectId } from '../../shared-lib/utils'
import { $BookingAddress } from '../models/booking'
import { $DialogData } from '../stores/UiStore'
import { hasLatLng, hasLocation } from './typeHelper'
import { ExternalBookingsSwQuery } from '../../graphQL/generated/client'
import { dateInTimezone } from '../../shared-lib/utils'
import { timeBrackets } from '../../shared-lib/utils'
import I18n, { useI18n } from '../../shared-lib/i18n/i18n'
import { $ExternalBooking } from '../../src/models/booking'

export function bookingDuration(items: Array<ExternalBookingItem>, bufferTime: Maybe<number>) {
  if (!bufferTime) bufferTime = 0
  return items.reduce((a, b) => a + b.duration, 0) + bufferTime
}

export function bookingTotal(items: Array<ExternalBookingItem>) {
  return items.reduce((a, b) => a + b.fee, 0)
}

export function treatmentItemDataForPopup(item: ExternalBookingItem, i18n: I18n) {
  let body = item.description ? `${item.description}\n` : ''
  body += `${i18n.currencyFormat(item.fee)} · ${item.duration} mins`

  return {
    title: item.name,
    body: body,
    buttons: [
      {
        title: 'Close',
      },
    ],
    wordBreak: 'break-all',
  } as Omit<$DialogData, 'open'>
}

export const calculateDepositAmount = (depositAmount: number, totalPrice: number, useFixedAmount: boolean) => {
  if (depositAmount === 0) return depositAmount

  const deposit = useFixedAmount
    ? Math.min(depositAmount, totalPrice)
    : Math.round(totalPrice * depositAmount / 100)

  if (deposit < 100 && deposit > 0) return 100

  return deposit
}

export const proStaticLocations = (saasSettings: SaasSettings | undefined | null) => {
  return [
    ...(saasSettings?.staticLocations ?? []),
    ...(saasSettings?.staticLocation ? [saasSettings?.staticLocation] : []),
  ]
    .filter(Boolean) as SaasSettingsStaticLocation[]
}

export const locationDetailsForBooking = (booking: Pick<ExternalBooking, 'address' | 'isProfessionalAddress' | 'status'>, saasSettings: SaasSettings | undefined | null): LocationDetails => {
  const { isProfessionalAddress, status, address } = booking

  if (isProfessionalAddress) {
    const location = proStaticLocations(saasSettings).find(location => location.externalAddressId === address._id)

    const hideFullAddress = ![
      ExternalBookingStatus.Accepted,
    ].includes((status as ExternalBookingStatus)) && location?.hideFullAddress

    return {
      name: location?.name,
      summary: location?.summary,
      hideFullAddress,
    }
  }

  return {
    name: 'Your address',
    summary: '',
    hideFullAddress: false,
  }
}

export const hasValidAddressHelper = (address: unknown) => {
  if (hasLatLng(address) && !!address.lat && !!address.lng) {
    return true
  }

  if (hasLocation(address)) {
    return address.location?.coordinates.length === 2
  }

  return false
}

export const showConsultationFormForStatus = (status: ExternalBookingStatus | undefined | null) => {
  return ![
    ExternalBookingStatus.Draft,
    ExternalBookingStatus.Cancelled,
    ExternalBookingStatus.Requested,
  ].includes(status ?? ExternalBookingStatus.Draft)
}

export const filterAvailableTreatments = (externalTreatmentMenu: ExternalTreatmentMenuQuery['externalTreatmentMenu'], bookingItemIds: string[], bookingLocationId: string) => {
  const availableTreatments = externalTreatmentMenu
    .filter(treatment => bookingItemIds
      .includes(treatment._id))
    .map(treatment => {
      const bookedLocationInfo = treatment
        .locations
        .find(({ locationId }) => locationId === bookingLocationId)

      const isAvailable = !bookedLocationInfo || bookedLocationInfo.enabled

      const {
        bufferTime,
        price,
        showOnPortal,
        ...relevantTreatmentData
      } = treatment

      return isAvailable
        ? {
          ...relevantTreatmentData,
          _id: generateObjectId(),
          treatmentId: relevantTreatmentData._id,
          fee: bookedLocationInfo?.price ?? price,
        }
        : undefined
    }).filter(Boolean)

  return bookingItemIds.map(itemId => {
    return availableTreatments.find(availableTreatment => availableTreatment.treatmentId === itemId)
  }).filter(Boolean)
}

export const bookedAddressFromSaasSettings = (bookingAddress: $BookingAddress, saasSettings: SaasSettings) => {
  const { staticLocation, staticLocations } = saasSettings
  const { locationId } = bookingAddress
  if (locationId === MOBILE_AVAILABILITY_ID && saasSettings.mobileLocation?.enabled) {
    return { ...bookingAddress, locationId, bufferTime: saasSettings.mobileLocation?.defaultPackdownTime }
  }

  const location = [
    staticLocation,
    ...(staticLocations ?? []),
  ].find(location => location?._id === locationId)

  if (location && location?.enabled) {
    const address = saasSettings
      .staticAddresses
      ?.find(address => address?._id === location.externalAddressId)

    if (address) {
      return {
        ...address,
        locationId: location._id,
        bufferTime: location.defaultPackdownTime,
      }
    }
  }
  return undefined
}

export type ExternalBookingsSW = ExternalBookingsSwQuery['externalBookingsSW']
export type ExternalBookingSW = ExternalBookingsSwQuery['externalBookingsSW'][number]

type Booking = ExternalBookingSW | ExternalBooking | $ExternalBooking
type TreatmentText = {
  timeBracketsText: string;
  durationText: string;
}

export const getTreatmentTimeText = (booking: Booking, bookingDuration?: number): TreatmentText => {
  const { totalDuration, date, bufferTime, timezone } = booking
  const dateInTz = dateInTimezone(date, timezone)
  const { s, pluralise } = useI18n()

  const duration = bookingDuration || totalDuration
  const treatmentDuration = (duration || 0) - (bufferTime || 0)
  const timeBracketsText = timeBrackets(dateInTz, treatmentDuration)
  const durationText = s('$0 $1 duration', treatmentDuration, pluralise('minute', treatmentDuration))

  return { timeBracketsText, durationText }
}